.footer-space {
  display: block;
  height: 70px;
}
.footer {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  background: #202020;
  text-align: center;
  padding: 16px;

  img {
    margin: auto;
    height: 100%;
  }
}