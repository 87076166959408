@import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Rajdhani");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

html {
  height: 100%;
}
body {
  background-color: #f0f0f0;
  min-height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  font-family: "Roboto", sans-serif;
  color: #f0f0f0;
}
a {
  color: inherit !important;
}
a:hover {
  color: inherit !important;
}
.font-22 {
  font-size: 22px;
}
.text-red {
  color: #c11e1e;
}
.separator-red {
  background-color: #860000;
  width: 30%;
  opacity: 1;
  margin: 1rem auto;
}
.separator-white {
  background-color: #fff;
  width: 30%;
  opacity: 1;
  margin: 1rem auto;
}
.btn-red {
  background-color: #c11e1e;
  border-radius: 4px;
  color: #f0f0f0;
  box-shadow: unset;
}
.btn-red:hover {
  background-color: #d52323;
  color: #f0f0f0;
  box-shadow: unset;
}
.btn-red:active {
  background-color: #8f1818;
  color: #f0f0f0;
  box-shadow: unset;
}
.btn-red:focus {
  box-shadow: unset;
}
.btn-white {
  background-color: #d2d2d2;
  border-radius: 4px;
  color: #333;
  box-shadow: unset;
}
.btn-white:hover {
  background-color: #d2d2d2;
  color: #333;
  box-shadow: unset;
}
.btn-white:active {
  background-color: #c1c1c1;
  color: #333;
  box-shadow: unset;
}
.btn-white:focus {
  box-shadow: unset;
}

/* AWESOME BUTTON */
.aws-btn {
  --button-default-height: 51px !important;
  --button-default-font-size: 14px !important;
  --button-default-border-radius: 2px !important;
  --button-horizontal-padding: 20px !important;
  --button-raise-level: 4px !important;
  --button-hover-pressure: 1 !important;
  --transform-speed: 0.175s !important;
  --button-primary-color: #540000 !important;
  --button-primary-color-dark: #420000 !important;
  --button-primary-color-light: #ffffff !important;
  --button-primary-color-hover: #520000 !important;
  --button-primary-color-active: #420000 !important;
  --button-primary-border: 0px solid #FFFFFF !important;
  --button-secondary-color: #f0f0f0 !important;
  --button-secondary-color-dark: #cccccc !important;
  --button-secondary-color-light: #333333 !important;
  --button-secondary-color-hover: #ededed !important;
  --button-secondary-color-active: #cccccc !important;
  --button-secondary-border: 0px solid #FFFFFF !important;
  --button-anchor-color: #0e4f88 !important;
  --button-anchor-color-dark: #072743 !important;
  --button-anchor-color-light: #ffffff !important;
  --button-anchor-color-hover: #0d4a7f !important;
  --button-anchor-border: none !important;
}