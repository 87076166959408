.index-header {
  background: #540000;
  text-align: center;
  padding: 35px;

  .index-header-profile-img {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    border: 2px solid #fff;
  }
  .index-header-profile-name {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    font-family: MontSerrat,sans-serif;
    font-weight: 500;
    font-size: 2rem;
    color: #f0f0f0;
  }
  .index-header-summary {
    color: #f0f0f0;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  /* Header button */
  .index-header-btn {
    padding: 10px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    border: 2px solid #f0f0f0;
    z-index: 1;
    color: #f0f0f0;

    i {
      margin-right: 0.15rem;
    }
  }
  .index-header-btn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #f0f0f0;
    transition: all 0.3s ease;
  }
  .index-header-btn:hover {
    color: rgb(0, 0, 0);
  }
  .index-header-btn:hover:after {
    top: 0;
    height: 100%;
  }
  .index-header-btn:active {
    top: 2px;
  }
}

.index-projects {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #2f2f2f;

  h2 {
    margin-bottom: 25px;
    margin-top: 10px;
    text-align: center;
  }
  .index-project-img {
    img {
      width: 100%;
    }
  }
  .index-project-info {
    position: relative;
    h2 {
      text-align: left;
      margin-top: unset;
      margin-bottom: 0.5rem;
    }
  }
  .btn-more-info {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 992px) {
  .index-project-info {
    h2 {
      margin-top: 15px !important;
      margin-bottom: 0.5rem !important;
    }
  }
  .btn-more-info {
    position: unset !important;
    bottom: unset !important;
  }
}