.slider {
  width: 100%;
  max-width: 400px;
  height: 218px;
  position: relative;
}
.slide1,
.slide2,
.slide3,
.slide4,
.slide5 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.slide1 {
  animation: fade 12s infinite;
  -webkit-animation: fade 12s infinite;
}
.slide2 {
  animation: fade2 12s infinite;
  -webkit-animation: fade2 12s infinite;
}
.slide3 {
  animation: fade3 12s infinite;
  -webkit-animation: fade3 12s infinite;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  33.333% {
    opacity: 0;
  }
  66.666% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade2 {
  0% {
    opacity: 0;
  }
  33.333% {
    opacity: 1;
  }
  66.666% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade3 {
  0% {
    opacity: 0;
  }
  33.333% {
    opacity: 0;
  }
  66.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
