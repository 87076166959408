.project-header {
  background: #540000;
  padding: 15px;
}
.project-header p {
  color: #f0f0f0;
  font-size: 20px;
  text-align: left;
  line-height: 25px;
  flex-grow: 1;
}
.project-header h1 {
  color: #f0f0f0;
  font-size: 40px;
  margin-bottom: 0.5rem;
}
.btn-download {
  font-size: 1rem;
}
.project {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  background-color: #f0f0f0;
  color: #333;
}
.project h2 {
  margin-top: 10px;
}
.project-info {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 50px;
}
.project-info li {
  margin-bottom: 10px;
}
.donation-input {
  border-radius: 2px;
  transition: 0.3s;
}
.donation-input:active {
  box-shadow: unset;
  border: 1px solid #333;
}
.donation-input:focus {
  box-shadow: unset;
  border: 1px solid #333;
}
.donation-label {
  width: 100%;
  font-size: 12px;
}
.donation-info {
  width: 100%;
  font-size: 12px;
}
.div-btn-donation {
  margin-top: 37px;
}
.btn-donation {
  background-color: #00b108;
  border-radius: 4px;
  color: #f0f0f0;
  box-shadow: unset;
  width: 100%;
}
.btn-donation:hover {
  background-color: #00c009;
  color: #f0f0f0;
  box-shadow: unset;
}
.btn-donation:active {
  background-color: #009907;
  color: #f0f0f0;
  box-shadow: unset;
}
.btn-donation:focus {
  box-shadow: unset;
}

@media screen and (max-width: 768px) {
  .project-header p {
    font-size: 15px;
  }
  .project-header h1 {
    font-size: 25px;
  }
  .project-header .container {
    flex-direction: column;
  }
  .slider {
    max-width: 280px;
    height: 153px;
    margin: 20px auto;
    position: relative;
  }
  .btn-download {
    font-size: 1rem;
    width: 100%;
  }
}
@media screen and (max-width: 1140px) {
  .div-btn-donation {
    margin-top: 0;
  }

  .btn-donation {
    margin: 5px 0;
  }
}

.moip-donate-btn {
  display: inline-block;
}
