.navbar {
  background-color: #202020;
  color: #f0f0f0;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding: unset;
  font-family: "Rajdhani", sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.navbar-container {
  position: relative;
}
.navbar-logo img {
  width: 140px;
}
.navbar-toggle {
  color: #f0f0f0;
}
.navbar-item {
  height: 55px;
  transition: 0.25s;
}
.navbar-item:hover {
  background-color: #860000;
}
.navbar-item .nav-link {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.navbar-space {
  height: 55px;
}

@media screen and (max-width: 992px) {
  .navbar {
    display: none;
  }
  .navbar-space {
    display: none;
  }
}